@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/component-library/styles/border";
@import "~@kaizen/deprecated-component-library-helpers/styles/type";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

$thumb-color: $color-blue-500;
$thumb-color-hover: $color-blue-600;
$thumb-radius: 100%;
$thumb-border-width: 4px;
$thumb-border-color: $color-white;
$thumb-height: 26px;
$thumb-width: 26px;
$thumb-height-with-border: $thumb-height + ($thumb-border-width * 2);
$thumb-width-with-border: $thumb-width + ($thumb-border-width * 2);

// The range
$track-progress-color: $color-green-500;
$track-color: $color-gray-500;
$track-radius: 4px;
$track-height: 3px;
$progress-bar-height: 5px;

@mixin track {
  width: auto;
  height: 0;
  box-sizing: border-box;
}

@mixin thumb {
  box-sizing: content-box;
  width: $thumb-width;
  height: $thumb-height;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  background: $thumb-color;
  cursor: pointer;

  transition: all 0.2s;
  transition-property: background;

  &:hover {
    background: $thumb-color-hover;
  }
}

.container {
  @include ca-margin($end: $ca-grid * 0.75);
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.rangeInputContainer {
  width: 100%;
  padding-top: $thumb-border-width;
  z-index: 1;
}

//extra input[type="range"] is required to override materialize.css in performance-ui
input[type="range"].rangeInput {
  all: unset;
  appearance: none;
  width: 100%;
  margin: 0; //performance-ui materialize override
  cursor: pointer;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;
  }

  :global(.js-focus-visible) &:global(.focus-visible) {
    outline: 2px solid $color-blue-500;
  }

  &::-webkit-slider-runnable-track {
    @include track;
    margin: $spacing-sm 0;
  }

  &::-moz-range-track {
    @include track;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ($thumb-height-with-border/2) * -1;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    color: transparent;
    border-width: $thumb-width 0;
    border-color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    border: none;
    border-radius: $track-radius * 2;
    background: $track-color;
  }

  &::-ms-fill-upper {
    border: none;
    border-radius: $track-radius * 2;
    background: $track-color;
  }

  &::-ms-thumb {
    @include thumb;
  }
}

.thumbValueContainer {
  position: absolute;
  width: 100%;
  top: -1 * $thumb-height-with-border / 2;
}

.thumbValue {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 0;
}

.pseudoTrackContainer {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .pseudoTrack {
    position: relative;
    left: 1px;
    width: calc(100% - 2px);
    height: $track-height;
    background: $track-color;
    border-radius: $track-radius;
  }

  .progressBar {
    position: absolute;
    z-index: 1;
    height: $progress-bar-height;
    background: $track-progress-color;
    border-radius: $track-radius;
  }
}

.spokes {
  position: absolute;
  top: $thumb-height-with-border;
  display: flex;
  width: 100%;
  padding: 0 (($thumb-width-with-border) / 2);
}

.spokeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0;

  &:last-of-type {
    margin-left: auto;
  }
}

.spoke {
  width: 0;
  height: 0;
  background: $track-color;
  border: 2px solid $track-color;
  border-radius: 100%;
  @include ca-margin($bottom: $spacing-sm);
}
