@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/component-library/styles/grid";
@import "src/styles/utils";

.outgoingGoalAlignments {
  @include ca-margin($top: $ca-grid * 0.5);
}

.alignedGoal {
  @include button-reset;
  width: 100%;
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: $color-gray-100;
  cursor: pointer;
  @include ca-margin($top: $ca-grid * 0.25, $bottom: $ca-grid * 0.25);
  @include ca-padding(
    $top: 5px,
    $bottom: 5px,
    $start: $ca-grid * 0.5,
    $end: $ca-grid * 0.5
  );

  &:hover {
    background-color: $color-gray-300;
  }
}
