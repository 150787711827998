@import "~@kaizen/design-tokens/sass/typography";
@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/responsive";

.container {
  position: relative;
  z-index: 2;
  display: flex;
  @include ca-padding(
    $start: $ca-grid * 0.5,
    $end: $ca-grid * 0.5,
    $top: $ca-grid * 0.25,
    $bottom: $ca-grid * 0.25
  );

  @include ca-media-mobile {
    flex-direction: column;
  }
}

.calendarContainer {
  @include ca-media-mobile {
    @include ca-margin($top: $ca-grid * 0.5);
  }
}

.metaWithActionContainer {
  @include ca-padding($top: $ca-grid * 0.25, $end: $ca-grid * 0.5);
  display: flex;
  justify-content: space-between;

  @include ca-media-mobile {
    justify-content: flex-end;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.metadata {
  @include ca-padding(
    $top: $ca-grid * 0.5,
    $end: $ca-grid * 0.5,
    $bottom: $ca-grid * 0.5
  );
  width: 100%;
  text-align: right;
  color: $color-purple-800;
  font-family: $typography-paragraph-body-font-family;
  font-weight: $typography-paragraph-bold-font-weight;
  font-size: $typography-paragraph-body-font-size;
  line-height: $typography-paragraph-body-line-height;
  letter-spacing: $typography-paragraph-body-letter-spacing;
  opacity: 0.75;

  @include ca-media-mobile {
    display: none;
  }
}

.divider {
  border-top: 1px solid rgba($color-gray-600-rgb, 0.1);
  @include ca-margin($start: -4px, $end: -4px);
}

.buttonCancelContainer {
  @include ca-margin($end: 12px);
}
