@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/typography";
// Using :global here, since it was a legacy component, and there
// are some classes that are overriding the "MarkdownText" class.
// Ideally this style should be properly scoped.
:global(.MarkdownText) {
  display: block;

  // REVIEW: normally Markdown ignores single newlines and other non-syntax whitespace, in the sense
  // that they're emitted verbatim, as whitespace, rather than as `<br>` etc. In our case, the text
  // we're rendering is almost always user input, so we actually want to represent the whitespace in
  // the source. For instance, if the user added a single return in the middle of text, that should
  // be represented in the rendered output.
  white-space: pre-wrap;

  &--inline {
    display: inline;
    white-space: normal;
  }

  // Children

  p {
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding: 0 0 0 30px;
  }

  ol {
    list-style: decimal;
  }

  ul > li {
    list-style-type: disc;
  }

  strong {
    font-weight: $typography-paragraph-bold-font-weight;
  }

  &--content-block {
    margin: 0 0 20px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--mention {
    display: inline-block;
    padding: 0 2px;
    border-radius: 2px;
    background: rgba($color-purple-500-rgb, 0.15);
  }

  a {
    color: $color-blue-500;
    text-decoration: underline;
    word-break: break-all;
  }
}
