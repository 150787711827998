@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/typography";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

$border-radius: 7px;

.container {
  width: 250px;
  @include ca-padding($top: $ca-grid * 0.5, $end: $ca-grid * 0.5);
}

.button {
  appearance: none;
  background: transparent;
  border: none;
  margin: 0;
  transition: none;
  display: block;
  align-items: center;
  box-sizing: border-box;
  height: 42px;
  position: relative;
  cursor: pointer;
  width: 100%;
  font-family: $typography-paragraph-body-font-family;
  font-weight: $typography-paragraph-body-font-weight;
  font-size: $typography-paragraph-body-font-size;
  line-height: $typography-paragraph-body-line-height;
  letter-spacing: $typography-paragraph-body-letter-spacing;
  color: $color-purple-800;
  text-align: left;

  &:hover {
    background-color: $color-blue-100;
    color: $color-blue-500;
    border-radius: $border-radius;
  }
}

.button.selected {
  color: $color-blue-500;
  font-weight: $typography-heading-6-font-weight;
  border-radius: $border-radius;

  &:hover {
    background-color: $color-blue-100;
    border-radius: $border-radius;
  }
}

.stringLabel {
  @include ca-padding($start: $ca-grid / 4);
  @include ca-margin($bottom: $ca-grid / 2);
}
