.Loader {
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-blend-mode: color;
  z-index: 11;
  height: 60px;
  width: 60px;
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: 50%;
}
