@import "~@kaizen/design-tokens/sass/color";
@import "../variables";
@import "~@kaizen/component-library/styles/animation";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/responsive";

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  transition: margin-right $ca-duration-rapid ease,
    margin-left $ca-duration-rapid ease;
  padding-top: $mobile-titleblock-height;
  background-color: $color-gray-100;
  &.hasDrawer {
    margin-right: $drawer-peek;
  }

  @include layout-dossier-should-not-overlap {
    &.dossierOpen {
      margin-right: $dossier-desktop-max-width;
      &.dossierIsSmall {
        margin-right: $small-dossier-desktop-max-width;
      }
    }
    &.hasDrawer.dossierOpen {
      margin-right: $dossier-desktop-max-width + $drawer-peek;
      &.dossierIsSmall {
        margin-right: $small-dossier-desktop-max-width + $drawer-peek;
      }
    }
  }

  :global(.ZenHeader--enabled) & {
    padding-top: 0;
  }
}

.headingWrapper {
  width: 100%;
}

.container {
  flex: 1;
  width: 100%;
  padding-bottom: $ca-grid;
  position: relative;

  &.defaultColor {
    background-color: $color-gray-100;
  }

  &.adminColor {
    background-color: $color-white;
  }
}

@include layout-navigation-visible {
  .container {
    padding-top: $ca-grid;
    :global(.HeaderCard) {
      margin-top: -$ca-grid;
    }
  }
  .root {
    padding-top: 0;
  }
}

@include layout-navigation-drawer-visible {
  .container {
    :global(.ZenHeader--enabled) & {
      margin-bottom: $mobile-drawer-height;
    }
  }
}
