.readOnlyButton {
  background: none;
  border: none;
  display: flex;
  height: 48px;
  padding-top: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}
