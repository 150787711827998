@import "~@kaizen/design-tokens/sass/border";
@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/typography";
@import "~@kaizen/design-tokens/sass/shadow";

@mixin button-reset {
  appearance: none;
  background: transparent;
  border: none;
  font: inherit;
  margin: 0;
  padding: 0;
  transition: none;
}

@mixin button-focus-visible {
  // Use JS polyfill to simulate :focus-visible, not yet supported by browsers
  // https://github.com/WICG/focus-visible#backwards-compatibility
  :global(.js-focus-visible) & {
    // hide native focus ring when :focus but not :focus-visible
    &:focus {
      outline: none;
    }

    // show custom focus ring when :focus-visible
    &:global(.focus-visible)::after {
      $focus-ring-offset: calc((#{$border-focus-ring-border-width} * 2) + 1px);
      content: "";
      position: absolute;
      background: transparent;
      border-radius: $border-focus-ring-border-radius;
      border-width: $border-focus-ring-border-width;
      border-style: $border-focus-ring-border-style;
      border-color: $color-blue-500;
      top: calc(-1 * #{$focus-ring-offset});
      left: calc(-1 * #{$focus-ring-offset});
      right: calc(-1 * #{$focus-ring-offset});
      bottom: calc(-1 * #{$focus-ring-offset});
    }
  }
}

@mixin button-before-pseudoelement {
  &::before {
    cursor: pointer;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    z-index: 1;
  }
}

.container {
  width: 260px;
  position: relative;
}

$card-padding: $spacing-sm;

.bodyContainer {
  position: relative;
  padding: $card-padding;
  border-top-left-radius: $border-borderless-border-radius;
  border-top-right-radius: $border-borderless-border-radius;
  border-bottom: 1px solid $color-gray-200;
  height: 150px;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: $color-gray-100;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.goalName {
  @include button-reset;
  @include button-focus-visible;
  @include button-before-pseudoelement;
  text-align: start;

  p {
    height: calc(#{$typography-paragraph-body-line-height} * 2);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  a {
    color: black;
  }
}

.subtitle {
  overflow: hidden;
  margin-bottom: $spacing-xs;
  display: -webkit-box;
  text-transform: capitalize;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardFooterContainer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

$EXPAND_COLLAPSE_BUTTON_SIZE: 30px;

.expandCollapseButton {
  @include button-reset;
  @include button-focus-visible;
  @include button-before-pseudoelement;
  height: $EXPAND_COLLAPSE_BUTTON_SIZE;
  display: flex;
  align-items: center;
}

.incomingCount {
  background-color: $color-white;
  height: $EXPAND_COLLAPSE_BUTTON_SIZE;
  width: $EXPAND_COLLAPSE_BUTTON_SIZE;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow-small-box-shadow;
}

.expandCollapseContainer {
  position: relative;
  padding: 8px 0;
  border-bottom-left-radius: $border-borderless-border-radius;
  border-bottom-right-radius: $border-borderless-border-radius;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: $color-blue-500;

  &:hover {
    background-color: $color-gray-100;
  }
}

.arrowDown {
  position: relative;
  top: 4px;
}
