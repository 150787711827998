@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/component-library/styles/border";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  display: flex;
  flex-direction: row;
}

.content {
  @include ca-padding($start: $ca-grid * 0.5);
  flex: 1;
}

.messageContainer {
  padding: 10px;
  background: $color-gray-100;
  border-radius: $ca-border-radius;
  @include ca-margin($top: $ca-grid * 0.5, $bottom: $ca-grid * 0.5);
}

.message {
  @include ca-margin($top: $ca-grid * 0.5);
}
