@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/component-library/styles/border";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/deprecated-component-library-helpers/styles/type";
@import "~@kaizen/design-tokens/sass/typography";

$cell-border-radius: 3px;

.calendar {
  display: flex;
  flex-direction: column;
  color: $color-purple-800;
  font-family: $typography-paragraph-body-font-family;
  font-size: $typography-paragraph-body-font-size;
  letter-spacing: $typography-paragraph-body-letter-spacing;
  font-weight: $typography-paragraph-body-font-weight;
  line-height: $typography-paragraph-body-line-height;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.weekdays {
  display: flex;
  flex-direction: row;
  margin-bottom: $ca-grid / 2;
}

.day {
  @include ca-type-ideal-small;
  @include ca-inherit-baseline;
  padding: 0;
  border: none;
  background-color: $color-white;
  border-radius: $cell-border-radius;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  outline: 0;

  &:hover {
    background-color: $color-blue-200;
  }
  &:disabled {
    color: rgba($color-purple-800-rgb, 0.3);
    opacity: 1;
    pointer-events: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.dayName {
  text-align: center;
  width: 42px;
}

.between {
  background-color: $color-blue-100;
  color: $color-blue-500;
}

.today {
  font-weight: bold;
  color: $color-blue-500;
}
.selected {
  color: white;
  font-weight: bold;
  background-color: $color-blue-500;
}
.selected:hover {
  background-color: $color-blue-400;
  color: white;
}

.empty {
  border: none;
  @include ca-padding($start: 1px);
  background-color: $color-white;
}

.empty:disabled {
  background-color: $color-white;
}
