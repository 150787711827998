/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
/** THIS IS AN AUTOGENERATED FILE **/
.rc-tooltip {
  opacity: 1;
  color: var(--color-purple-800, #2f2438);
  padding: 0;
  border: 1px solid var(--color-purple-200, #dfc9ea);
  border-radius: 3px;
  background-color: transparent;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.rc-tooltip-content {
  border-radius: 3px;
}
.rc-tooltip-inner {
  padding: 0;
  border: none;
  border-radius: 3px;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-bottom-color: var(--color-purple-200, #dfc9ea);
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: var(--color-purple-200, #dfc9ea);
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  border-left-color: var(--color-purple-200, #dfc9ea);
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  border-right-color: var(--color-purple-200, #dfc9ea);
}
.icon-unread::after {
  content: "•";
  color: var(--color-orange-500, #e96c2f);
  font-size: 24px;
}
.icon-incomplete::after {
  content: "○";
  color: var(--color-orange-500, #e96c2f);
  font-style: normal;
  font-size: 21px;
}
.icon-done::after {
  content: "○";
  visibility: hidden;
  font-size: 24px;
}
.icon-share {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 20px;
  width: 18px;
  background-size: cover;
  background-image: url("./static/images-2017/icons/icon-share.png");
}
.icon-star-yes {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-image: url("./static/images-2017/icons/icon-star-yes-2.png");
  background-size: cover;
}
.icon-star-no {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-image: url("./static/images-2017/icons/icon-star-no-2.png");
  background-size: cover;
}
.icon-envelope {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 28px;
  background-image: url("./static/images-2017/icons/icon-envelope.png");
}
.icon-heart {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  background-image: url("./static/images-2017/icons/icon-heart.png");
  background-size: cover;
}
.icon-heart-filled {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  background-image: url("./static/images-2017/icons/icon-heart-filled.png");
  background-size: cover;
}
/**
* Campification
*/
body {
  font-family: var(--typography-paragraph-body-font-family, "Inter", "Noto Sans", Helvetica, Arial, sans-serif);
  font-weight: 300;
  letter-spacing: var(--typography-paragraph-body-letter-spacing, normal);
  font-size: var(--typography-paragraph-small-font-size, 0.875rem);
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.btn-flat.btn-bordered {
  font-family: var(--typography-button-primary-font-family, "Inter", "Noto Sans", Helvetica, Arial, sans-serif);
  font-weight: 500;
  letter-spacing: var(--typography-button-primary-letter-spacing, normal);
  font-size: var(--typography-button-primary-font-size, 1.125rem);
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
/**
 * In-between a heading and body text - a little larger than body text but
 * lighter than a header.
 *
 * Example:
 *
 * 		<h4>Company Settings</h4>
 * 		<p class="subheading">
 * 			Adjust your company's avatar, title, etc.
 * 		</p>
 */
.subheading {
  font-size: 1.125rem;
  line-height: 1.5;
  color: rgba(var(--color-purple-800-rgb, 47, 36, 56), 0.8);
  margin: 0.4rem 0 1rem;
  text-align: center;
}
/**
 * Chops off the bottom margin of a heading to make it jive better with a
 * `.subheading`.
 *
 * Example:
 *
 * 		<h4 class="with-subheading">Company Settings</h4>
 * 		<p class="subheading">
 * 			Adjust your company's avatar, title, etc.
 * 		</p>
 */
.with-subheading {
  margin-top: 0;
  margin-bottom: 10px;
  margin-bottom: 0;
}
/**
 * Shaded lighter than regular text.
 *
 * Example:
 *
 * 		<p class="secondary-text">
 * 			This may or may not interest you
 * 		</p>
 */
.secondary-text {
  color: rgba(var(--color-purple-800-rgb, 47, 36, 56), 0.8);
}
.page-header {
  margin-top: 0;
  margin-bottom: 10px;
}
/* Structure
====================================== */
html {
  height: 100%;
  max-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
}
body {
  background: var(--color-gray-100, #f9f9f9);
  height: 100%;
  max-height: 100%;
  line-height: 19px;
  color: var(--color-purple-800, #2f2438);
  font-family: var(--typography-paragraph-body-font-family, "Inter", "Noto Sans", Helvetica, Arial, sans-serif);
  font-size: 16px;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: normal;
  font-kerning: normal;
}
@media (max-width: 500px) {
  #app {
    overflow-x: hidden;
  }
}
hr {
  border-top: 1px solid var(--color-gray-600, #524e56);
}
input::placeholder,
textarea::placeholder,
input::-moz-focus-inner,
textarea::-moz-focus-inner {
  color: rgba(var(--color-purple-800-rgb, 47, 36, 56), 0.8);
  opacity: 1;
}
.labeled-rule {
  position: relative;
  height: 2.5em;
  margin: 0 auto;
}
.labeled-rule p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  background-color: #eee;
  padding: 0px 25px;
}
::-moz-focus-inner {
  border: 0;
}
:focus {
  outline: none;
}
/* Animations
====================================== */
.animated {
  transition: all 0.5s ease-out;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulse-size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes raise {
  0% {
    transform: scaleY(0.01);
  }
  75% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
/* Utilities/Helpers/Mixins
====================================== */
@media (max-width: 500px) {
  .hide-on-mobile-only {
    display: none;
  }
}
@media (max-width: 900px) {
  .hide-on-small-only {
    display: none;
  }
}
.cf {
  *zoom: 1;
}
.cf::before,
.cf::after {
  content: "";
  display: table;
}
.cf::after {
  clear: both;
}
