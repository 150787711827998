@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.commentsContainer {
  @include ca-margin($top: $ca-grid * 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comments {
  @include ca-margin($top: $ca-grid * 0.5);
  overflow-y: auto;
  flex-grow: 1;
}

.mainMessage {
  flex-grow: 0;
  @include ca-margin($bottom: $ca-grid);
}

.commentThread {
  &:not(:first-child) {
    @include ca-margin($top: $ca-grid);
  }
}

.commentActions {
  display: flex;
  justify-content: flex-end;
}

.textFieldContainer {
  @include ca-margin($bottom: $ca-grid * 0.5);
}
