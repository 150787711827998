.iconContainer {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainerNoImage {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
}
