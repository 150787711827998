@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.keyResultsLabel {
  @include ca-margin($bottom: $ca-grid);
}

.keyResult {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include ca-margin($bottom: 20px);
}

.keyResultTitle {
  flex-grow: 0;
  width: 50%;
}

.progress {
  flex-grow: 1;
  @include ca-margin($start: $ca-grid * 2);
}

.addKeyResultButtonContainer {
  @include ca-margin($top: $ca-grid * 0.5);
}
