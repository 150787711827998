@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/shadow";
@import "../variables";
@import "~@kaizen/component-library/styles/animation";
@import "~@kaizen/component-library/styles/layers";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/responsive";

.dossier {
  z-index: $ca-z-index-fixed + 1; // one more than the nav, one less than drawer
  background-color: white;
  bottom: 0;
  box-shadow: $shadow-small-box-shadow;
  position: fixed;
  right: -100%;
  top: 0;
  transition: right $ca-duration-rapid $ca-ease-in;
  width: 100%;

  &.hasDrawer {
    width: calc(100% - #{$drawer-peek});
  }

  .dossierContainer {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: ($ca-grid * 1.5) $ca-grid ($ca-grid * 2);
  }

  &.dossierOpen.dossierOpen {
    right: 0;
  }

  &.dossierOpen.hasDrawer {
    right: $drawer-peek;
  }
}

.closeButton {
  margin: ($ca-grid * -1.5) ($ca-grid * -1);
  :global(.js-focus-visible) & {
    // hide native focus ring when :focus but not :focus-visible
    &:focus {
      outline: none;
    }

    // show custom focus ring when :focus-visible
    &:global(.focus-visible) {
      border: 2px solid $color-blue-500;
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.dossier {
  @media (min-width: #{$minimum-supported-body-width + $dossier-desktop-max-width}) {
    right: -$dossier-desktop-max-width;
    &.isSmall {
      right: -$small-dossier-desktop-max-width;
    }
    &:not(.hasDrawer) {
      max-width: $dossier-desktop-max-width;
      &.isSmall {
        max-width: $small-dossier-desktop-max-width;
      }
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.dossier.hasDrawer {
  &:not(.isSmall) {
    @media (min-width: #{$minimum-supported-body-width + $drawer-peek + $dossier-desktop-max-width}) {
      max-width: $dossier-desktop-max-width;
    }
  }
  &.isSmall {
    @media (min-width: #{$minimum-supported-body-width + $drawer-peek + $small-dossier-desktop-max-width}) {
      max-width: $dossier-desktop-max-width;
    }
  }
}

@include layout-navigation-visible {
  .dossier {
    top: $navbar-height;
    z-index: $ca-z-index-fixed - 2; // two less than nav, one less than drawer
  }
}

@include mobile-view {
  .dossier {
    z-index: $ca-z-index-fixed - 1; // one less than Kaizen TitleBlock to ensure any mobile controls are visible, one less than nav
  }
}
