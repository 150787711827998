@import "~@kaizen/design-tokens/sass/color";

@import "~@kaizen/deprecated-component-library-helpers/styles/type";

.card {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: $ca-grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.heading {
  text-align: center;
}

.textContent {
  max-width: 450px;
}

.imageContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.errorCode {
  @include ca-type-ideal(
    $size: 240,
    $line-height-in-grid-units: 10,
    $weight: $ca-weight-book
  );
  margin: 0;
}

.paragraph {
  @include ca-type-ideal-body;
}

.image {
  width: 300px;
  max-width: 100%;
}

.logo {
  max-width: 100%;
}

.errorCode400 {
  color: $color-orange-500;
}
.errorCode401 {
  color: $color-gray-100;
}
.errorCode403,
.errorCode422 {
  color: $color-purple-800;
}
.errorCode404 {
  color: $color-yellow-500;
}
.errorCode500,
.errorCode502 {
  color: $color-red-500;
}
