@import "~@kaizen/design-tokens/sass/border";

@import "~@kaizen/component-library/styles/border";

// Add a blue outline around the element, used for the focus states.
// Kaizen actually already supplies something similar, `form-input-focus-border`.
// The difference being, that this `focus-outline` mixin uses the `outline` property,
// rather than `border`. Both have their advantages and limitations, so choose
// whatever makes sense.
// Note, this function is documented under docs/accessibility.md. If you change this
// function, update the docs accordingly.
@mixin focus-outline {
  outline: $border-focus-ring-border-width $border-solid-border-style
    $ca-border-color-focus;
}
