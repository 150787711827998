@import "~@kaizen/design-tokens/sass/border";
@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/mixins/forms";
@import "~@kaizen/draft-form/KaizenDraft/Form/Primitives/_mixins.scss";

// NOTE: temporarily stolen from kaizen, which has different linting rules, hence all the ignores. This will
// soon be migrated to kaizen.
$ca-border-color-focus: $color-blue-500;
$ca-border-color-hover: $color-gray-600;
$ca-border-color-focus-reversed: $color-blue-300;

// -----------------------------------------------
// Form Input Primitive
// -----------------------------------------------

// Vars
$input-height: 48px;
$input-base-padding-horizontal: $ca-grid / 2;
$input-icon-size: 1.25rem; // 20px
$input-placeholder-opacity: 0.5;
$input-disabled-background: $color-gray-300;
$input-disabled-alpha: 30%;
$input-disabled-opacity: 0.3;
$input-disabled-border-alpha: 50%;
$input-pill-shaped-border: 999999999px;

///////////////////////////////////////////////////
// BASE STYLES
///////////////////////////////////////////////////

.wrapper {
  position: relative;
}

.input {
  @include form-input-reset;
  background-color: transparent;
  background-clip: padding-box;
  border: $border-solid-border-width $border-solid-border-style transparent;
  border-radius: $input-pill-shaped-border;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0 $input-base-padding-horizontal;
  height: $input-height;
  width: 100%;
  margin-bottom: 2px;

  @include form-input-placeholder {
    opacity: 1;
  }

  &:focus:not([disabled]),
  &:hover:focus:not([disabled]) {
    @include form-input-placeholder {
      opacity: 0;
    }
  }

  &.disabled {
    &:not(.reversed) {
      background: $color-gray-300;
    }

    @include form-input-placeholder {
      color: rgba($color-purple-800-rgb, $input-disabled-alpha);
    }
  }
}

.input:focus + .focusRing {
  $focus-ring-offset: 3px;
  position: absolute;
  background: transparent;
  border-radius: $input-pill-shaped-border;
  border-width: $border-focus-ring-border-width;
  border-style: $border-focus-ring-border-style;
  border-color: transparent;
  top: -$focus-ring-offset;
  left: -$focus-ring-offset;
  right: -$focus-ring-offset;
  bottom: -$focus-ring-offset;
  pointer-events: none;
}

// Default
.input.default {
  background-color: $color-white;
  color: $ca-base-text-color;
  border-color: $color-gray-500;
  display: flex;
  align-items: center;

  @include form-input-placeholder {
    color: $ca-base-text-color;
    opacity: $input-placeholder-opacity;
  }

  &:focus:not([disabled]),
  &:hover:focus:not([disabled]) {
    border-color: $color-gray-600;
  }

  &:focus + .focusRing {
    border-color: $color-blue-500;
  }

  &.disabled {
    color: rgba($color-purple-800-rgb, $input-disabled-alpha);

    @include form-input-placeholder {
      opacity: $input-disabled-opacity;
    }
  }

  &.error:not(:focus) {
    border-color: $ca-palette-input-validation-negative;
  }
}

// Reversed (Dark Backgrounds)
.input.reversed {
  color: $color-white;
  border-color: rgba($color-white-rgb, 0.65);
  background: rgba($color-white-rgb, 0.1);

  @include form-input-placeholder {
    color: $color-white;
    opacity: $input-placeholder-opacity;
  }

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    border-color: $color-white;
  }

  &:focus + .focusRing {
    border-color: $color-blue-300;
  }

  &.disabled {
    color: rgba($color-white-rgb, $input-disabled-alpha);
    border-color: rgba($color-white-rgb, $input-disabled-border-alpha);

    @include form-input-placeholder {
      opacity: $input-disabled-opacity;
    }
  }

  &.error:not(:focus) {
    border-color: $ca-palette-input-validation-negative;

    @include form-input-placeholder {
      opacity: $input-placeholder-opacity;
    }
  }
}

///////////////////////////////////////////////////
// ICON ADORNMENT STYLES
///////////////////////////////////////////////////

@mixin vertically-center-icon {
  position: absolute;
  height: $input-icon-size;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.withStartIconAdornment {
  .startIconAdornment {
    @include vertically-center-icon;
    @include ca-position($start: $ca-grid / 2);
  }

  &.withDisabled {
    .startIconAdornment {
      opacity: $input-disabled-opacity;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .input {
    @include ca-padding(
      $start: $ca-grid + $input-icon-size,
      $end: $input-base-padding-horizontal
    );
  }
}

.withEndIconAdornment {
  .endIconAdornment {
    @include vertically-center-icon;
    @include ca-position($end: $ca-grid / 2);
  }

  &.isDisabled {
    .endIconAdornment {
      opacity: $input-disabled-opacity;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .input {
    @include ca-padding(
      $start: $input-base-padding-horizontal,
      $end: $ca-grid + $input-icon-size
    );
  }
}

.withStartIconAdornment.withEndIconAdornment {
  /* stylelint-disable-next-line no-descending-specificity */
  .input {
    @include ca-padding(
      $start: $ca-grid + $input-icon-size,
      $end: $ca-grid + $input-icon-size
    );
  }
}

// Default theme
/* stylelint-disable-next-line no-duplicate-selectors */
.withStartIconAdornment {
  /* stylelint-disable-next-line no-duplicate-selectors, no-descending-specificity */
  .startIconAdornment {
    color: $color-purple-800;
    opacity: 0.5;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  &.withDisabled {
    /* stylelint-disable-next-line no-duplicate-selectors */
    .startIconAdornment {
      opacity: 0.3;
    }
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  &:hover:not(.withDisabled),
  &:focus-within:not(.withDisabled) {
    .startIconAdornment {
      opacity: 0.7;
    }
  }
}

// Reversed
.withStartIconAdornment.withReversed {
  /* stylelint-disable-next-line no-descending-specificity */
  .startIconAdornment {
    color: $color-white;
    opacity: 1;
  }

  &.withDisabled {
    .startIconAdornment {
      opacity: 0.3;
    }
  }

  &:hover:not(.withDisabled),
  &:focus-within:not(.withDisabled) {
    .startIconAdornment {
      opacity: 1;
    }
  }
}

.clearButton {
  position: absolute;
  background: none;
  border-color: $border-borderless-border-color;
  cursor: pointer;
  padding: 0;
  right: 0.75rem;
  bottom: 0.5rem;
}
