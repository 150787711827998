@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.button {
  display: flex;
  justify-content: flex-end;
  @include ca-margin(
    $top: $ca-grid / 2,
    $bottom: $ca-grid / 4,
    $end: $ca-grid / 2
  );
}

.selectAction {
  display: flex;
  @include ca-margin($start: 20px, $top: $ca-grid * 0.5);

  :hover {
    cursor: pointer;
  }
}

.emptyState {
  margin: $ca-grid;
  color: $color-gray-500;
}

.content {
  width: 300px;
  & label {
    width: auto;
  }
  @include ca-margin($start: 14px);
  max-height: 260px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.checkboxRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include ca-padding($start: $ca-grid * 0.25);

  :hover {
    cursor: pointer;
  }
}

.cancelButtonContainer {
  @include ca-margin($end: 12px);
}

.searchBox {
  @include ca-margin(
    $top: $ca-grid * 0.5,
    $start: $ca-grid * 0.5,
    $bottom: $ca-grid * 0.5,
    $end: $ca-grid * 0.5
  );
}

.loading {
  @include ca-margin($top: $ca-grid * 0.3);
}

.loadingPlaceholder {
  min-width: 60px;
}

.nestedRow {
  @include ca-margin($start: $ca-grid * 1.5);

  :hover {
    cursor: pointer;
  }
}

.radioGroup {
  max-width: 285px;
}
